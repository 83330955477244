<template>
    <div class="proinfopage u-rela">
        <div class="u-m-l-30 u-m-r-30">
            <div class="title u-flex u-font-30 green3 blod u-m-t-40 u-m-b-30">
                <i class="icon icon1"></i>
                规格参数
            </div>
            <div class="u-flex u-font-24 u-p-t-14 u-p-b-14 border-b u-p-l-10">
                <span class="u-tips-color u-flex-4">规格</span>
                <span class="u-main-color blod u-flex-8">{{good.specs}}</span>
            </div>
            <div class="u-flex u-font-24 u-p-t-14 u-p-b-14 border-b u-p-l-10">
                <span class="u-tips-color u-flex-4">产地</span>
                <span class="u-main-color blod u-flex-8">{{good.place}}</span>
            </div>
            <div class="u-flex u-font-24 u-p-t-14 u-p-b-14 border-b u-p-l-10">
                <span class="u-tips-color u-flex-4">保质期</span>
                <span class="u-main-color blod u-flex-8">{{good.shelf_life}}</span>
            </div>
            <div class="u-flex u-font-24 u-p-t-14 u-p-b-14 border-b u-p-l-10">
                <span class="u-tips-color u-flex-4">包装</span>
                <span class="u-main-color blod u-flex-8">{{good.packing}}</span>
            </div>
            <div class="u-flex u-font-24 u-p-t-14 u-p-b-14 border-b u-p-l-10">
                <span class="u-tips-color u-flex-4">储藏方法</span>
                <span class="u-main-color blod u-flex-8" >{{good.storage_method}}</span>
            </div>
            <div class="title u-flex u-font-30 green3 blod u-m-t-58">
                <i class="icon icon2"></i>
                商品详情
            </div>
            <p class="u-font-22 u-content-color u-m-t-20">
                红糖外形色泽嫩黄而略带青色，质地松软，散似细沙，纯净无渣，甘甜味鲜，清香可口。感官要求，色泽：淡黄、全黄略带青色。形态：粉状、略带小团粒，松散干燥。口味：清甜而带有香（鲜）味，无杂味。
            </p>
        </div>
        <!-- 详情 -->
        <div class="descriptimg"  v-html="good.detail"></div>

    </div>
</template>
<script>
    export default {
        name: "",
        data() {
            return {
              good: {
                'code': '',
                'name': '',
                'packing': '',
                'place': '',
                'specs': '',
                'storage_method': '',
                'subtitle': '',
                'shelf_life': '',
                'img_url': '',
                'img_type': '',
                 'detail': '',
              }
            };
        },
        mounted() {

                // this.$axios.get('/web/default/goods&id=498', {
                //     params: {
                //         // id:498
                //     }
                // }).then(res => {
                //     // console.log(res);
                //     //请求的数据存储在res.data 中
                //     if (res.data.status == 200) {
                //         this.good = res.data.data;
                //     }
                //     // console.log(this.good);
                // }).catch(function (error) {
                //     console.log(error);
                // });
            this.$axios.get('getGoodDetail', {
                params: {
                    code: this.$parent.code,
                    type:2
                }
            }).then(res => {
                //请求的数据存储在res.data 中
                if (res.data.status == 200) {
                    this.good = res.data.data;
                }
                // console.log(this.good);
            }).catch(function (error) {
                console.log(error);
            });
        },

        methods: {},
    };
</script>
<style lang="scss">
    @import "../../assets/css/applet.scss";
</style>
